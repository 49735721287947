<template>
  <div class="container">
    <el-form :model="searchForm" ref="searchForm" label-width="70px" class="searchForm">

      <el-row>
        <el-col :span='6'>
          <el-form-item label="项目名称">
            <el-select class="default" v-model="searchForm.proName" filterable default-first-option
              :placeholder="'请选择'" @change="handleSelect($event,0)" clearable>
              <el-option v-for="person in ProName" :key="person.id" :label="person.label"
                :value="person.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span='6'>
          <el-form-item label="负责人">
            <selects @handleChange="handleSelect($event,1)" :isAllow='true' ref="proManagerRef"
              :options="proManager">
            </selects>
          </el-form-item>
        </el-col>
        <el-col :span='6'>
          <el-form-item label="UI设计师">
            <selects @handleChange="handleSelect($event,2)" :isAllow='true' ref="UIRef"
              :options="proManager">
            </selects>
          </el-form-item>
        </el-col>
        <el-col :span='6'>
          <el-form-item label="前端">
            <selects @handleChange="handleSelect($event,3)" :isAllow='true' ref="weberRef"
              :options="proManager">
            </selects>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span='6'>
          <el-form-item label="后端">
            <selects @handleChange="handleSelect($event,4)" :isAllow='true' ref="devBackRef"
              :options="proManager">
            </selects>
          </el-form-item>
        </el-col>
        <el-col :span='6'>
          <el-form-item label="版本号">
            <el-input type="text" v-model="searchForm.proVersion" class="default"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span='6'>
          <el-form-item label="状态">
            <selects @handleChange="handleSelect($event,5)" ref="statusRef" :options="status">
            </selects>
          </el-form-item>
        </el-col>
        <el-col :span='6'>
          <el-button class="formBtn" @click="openNewProject(id=-1)">新建项目</el-button>
          <el-button class="formBtn btn-reset" @click="resetForm">重置</el-button>
          <el-button class="formBtn" @click="search(type=-1)" :loading="Searching">
            {{Searching ?  '搜索中...':'搜索'}}</el-button>
        </el-col>
      </el-row>

    </el-form>

    <createProject @createProject="handleNewProject" :Member="proManager" ref="createProject">
    </createProject>

    <!-- 项目列表 -->
    <div>
      <el-table :data="projectList" stripe v-loading="Searching" :row-style="{height:'64px'}"
        :header-cell-style="{'border-top':'solid 1px #E3E3E3','background': '#F5F5F5'}"
        style="width: 100%">
        <el-table-column prop="id" label="ID" width="70" align="center">
        </el-table-column>
        <el-table-column prop="proName" label="项目名称" min-width="180">
        </el-table-column>
        <el-table-column prop="proVersion" label="版本号" min-width="120">
        </el-table-column>
        <el-table-column prop="proManager" label="负责人" min-width="120">
        </el-table-column>
        <el-table-column prop="proDevUi" label="UI设计师" min-width="180">
        </el-table-column>
        <el-table-column prop="proDevFront" label="前端" min-width="180">
        </el-table-column>
        <el-table-column prop="proDevBack" label="后端" min-width="180">
        </el-table-column>
        <el-table-column prop="startTime" label="立项时间" min-width="180">
        </el-table-column>
        <el-table-column prop="onlineTime" label="上线时间" min-width="180">
        </el-table-column>
        <el-table-column prop="status" label="状态" min-width="120">
          <template slot-scope="scope">
            <span style="color: #3285EA">
              {{(scope.row.status==0) ? '立项' : ''}}
              {{(scope.row.status==1) ? '开发中' : ''}}
              {{(scope.row.status==2) ? '已上线' : ''}}
              {{(scope.row.status==3) ? '延期' : ''}}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" min-width="100">
          <template slot-scope="scope">
            <el-button type="text" @click="openNewProject(scope.row.id)">编辑</el-button>
            <el-button type="text" @click="deleteProject(scope.row.id)" class="delectBtn">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页器 -->
    <div class="pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="1" :page-sizes="[5, 10, 15]" :page-size="100"
        layout="total, sizes, prev, pager, next, jumper" :total="dataTotal">
      </el-pagination>
    </div>

  </div>
</template>

<script>
import {
  POST_PRJEDITADD,
  POST_PRJQULIST,
  GET_DELEPRJBYID,
} from "../request/api";
import { mapState } from "vuex";
import { selects, createProject } from "./parts";
export default {
  data() {
    return {
      dataTotal: 0,
      Searching: false, //搜索中
      searchForm: {
        id: null, //项目id
        proName: "", //项目名称
        proManager: "", //负责人
        proDevUi: [], //UI设计师
        proDevFront: [], //前端工程师
        proDevBack: [], //后端工程师
        proVersion: "", //版本号
        status: "", //状态

        pageNumber: 1,
        pageSize: 5,
      },
      //   项目名称
      ProName: [],
      //   负责人
      proManager: [],

      //状态
      status: [
        { value: "0", label: "立项" },
        { value: "1", label: "开发中" },
        { value: "2", label: "已上线" },
        { value: "3", label: "延期" },
      ],

      projectList: [],
    };
  },
  created() {
    this.getProName();
    this.search();
  },

  methods: {
    //获取项目名称
    getProName(val) {
      this.ProName = this.projectName; //获取项目列表
      this.proManager = this.Member; //获取人员列表
      this.searchForm.proName = this.currentProject.label; //获取选中项目名称

      this.$emit("updateProject", val);
    },
    // 重置搜索条件
    resetForm() {
      this.searchForm.proName = "";
      this.searchForm.proManager = "";
      this.searchForm.proDevUi = [];
      this.searchForm.proDevFront = [];
      this.searchForm.proDevBack = [];
      this.searchForm.proVersion = "";
      this.searchForm.status = "";
      let refList = [
        "proManagerRef",
        "statusRef",
        "weberRef",
        "UIRef",
        "devBackRef",
      ];
      refList.forEach((element) => {
        this.$refs[element].resetValue();
      });
      this.search();
    },
    // 下拉框选择赋值
    handleSelect(val, type) {
      if (type == 0) {
        val;
        // this.searchForm.proName = val;
      } else if (type == 1) {
        this.searchForm.proManager = val;
      } else if (type == 2) {
        this.searchForm.proDevUi.push(val);
        if (this.searchForm.proDevUi.length > 1) {
          this.searchForm.proDevUi.splice(0, 1);
        }
      } else if (type == 3) {
        this.searchForm.proDevFront.push(val);
        if (this.searchForm.proDevFront.length > 1) {
          this.searchForm.proDevFront.splice(0, 1);
        }
      } else if (type == 4) {
        this.searchForm.proDevBack.push(val);
        if (this.searchForm.proDevBack.length > 1) {
          this.searchForm.proDevBack.splice(0, 1);
        }
      } else {
        this.searchForm.status = val;
      }
    },
    // 打开新建项目对话框
    openNewProject(id) {
      this.$refs.createProject.openNewProject(id);
    },
    // 新建项目
    handleNewProject(val) {
      POST_PRJEDITADD(val)
        .then(({ data: res }) => {
          if (res.resCode == 1) {
            this.$notify({ title: "项目创建成功", type: "success" });
            this.search();
            this.getProName(res.data);
          } else {
            this.$notify.error({
              title: "项目创建失败",
            });
          }
          setTimeout(() => {
            this.$refs.createProject.handleClose();
          }, 400);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 查询搜索
    search(type) {
      if (type == -1) {
        this.searchForm.pageNumber = 1;
      }
      this.Searching = true;
      const waitsearch = setTimeout(() => {
        this.Searching = false;
      }, 600);
      POST_PRJQULIST(this.searchForm)
        .then(({ data: res }) => {
          if (res.resCode == 1) {
            this.dataTotal = res.data.total;
            this.projectList = res.data.list;
          }
          waitsearch;
        })
        .catch((err) => {
          console.log(err);
          waitsearch;
        });
    },
    //删除项目
    deleteProject(id) {
      this.$confirm("此操作将永久删除该项目, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          GET_DELEPRJBYID({ id })
            .then(({ data: res }) => {
              if (res.resCode == 1) {
                this.$notify.success({
                  title: "成功",
                  message: "删除成功",
                });
                this.search();
                this.getProName();
              } else {
                this.$notify.error({
                  title: "失败",
                  message: res.resMsg,
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },
    // 分页器
    handleSizeChange(val) {
      this.searchForm.pageSize = val;
      this.search();
    },
    handleCurrentChange(val) {
      this.searchForm.pageNumber = val;
      this.search();
    },
  },
  computed: {
    ...mapState(["currentProject", "projectName", "Member"]),
  },
  watch: {
    currentProject(newValue) {
      this.searchForm.proName = newValue.label;
    },
  },
  components: {
    selects,
    createProject,
  },
};
</script>

<style lang="less" scoped>
.default {
  width: 200px;

  /deep/ .el-input__inner {
    height: 36px;
    line-height: 36px;
    border-radius: 0;
  }
}

// 搜索按钮
.formBtn {
  height: 36px;
  background: #3285ea;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  line-height: 36px;
  padding: 0;
  border-radius: 0;
  padding: 0 22px;
}
.btn-reset {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.delectBtn {
  margin-left: 20px;
}
</style>